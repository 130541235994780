<template>
  <div class="login">
    <div class="login-div">
      <div class="logo">
        <img class="img" src="@/assets/login/logo.png">
      </div>
      <div class="logins">
        <div class="login-box flex-start flex-align">
          <div class="img-boxs">
            <img class="img" src="@/assets/login/logo_form_bg.png">
          </div>
          <div class="forms-div">
            <h1>欢迎使用嘉园数字平台</h1>
            <!-- 表单区域 -->
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
              <el-form-item label="" prop="type">
                <el-select v-model="form.type" filterable placeholder="请选择试用账户" style="width: 99%">
                  <el-option v-for="item in typeList" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="账号" prop="account" class="input-form">
                <el-input v-model="form.account" placeholder="请输入试用账户" maxlength="120" />
              </el-form-item>
              <el-form-item label="密码" prop="password" class="input-form">
                <el-input v-model="form.password" placeholder="请输入密码" maxlength="120" type="password" />
              </el-form-item>
            </el-form>
            <div class="btn-group">
              <div>
                <el-button :loading="btnLoading" type="primary" size="medium" color="#1E8CFF" @click="submitLogin('form')">登录</el-button>
              </div>
              <div class="mt-20">
                <el-button type="primary" size="medium" color="#1E8CFF" @click="slipPage">点击申请试用账户</el-button>
              </div>
            </div>
            <div class="mt-20">
              <el-checkbox v-model="autoLogin">自动登录</el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <p>版权所有 © 中嘉数字科技（浙江）有限公司2020-2022 浙ICP备2020041135号-1</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {

  },
  data() {
    return {
      form: {
        type: '',
        account: '',
        password: ''
      },
      rules: {
        type: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请选择试用账户'
          }
        ],
        account: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请输入试用账户'
          }
        ],
        password: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请输入密码'
          }
        ]
      },
      btnLoading: false,
      autoLogin: false,
      typeList: [{ id: 0, title: 'JiaPark' }, { id: 2, title: 'JiaHealth' }, { id: 1, title: 'JiaAEC' }, { id: 3, title: 'JiaMobility' }]
    };
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    submitLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          setTimeout(() => {
            this.btnLoading = false;
            this.$message.warning('账号密码错误');
          }, 400);
        }
      });
    },
    slipPage() {
      setTimeout(() => {
        this.$router.push({
          path: '/freeTrial'
        });
      }, 800);
    }
  }
};
</script>

<style scoped lang="scss">
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.mt-20 {
  margin-top: 20px;
}
.login {
  position: relative;
  // height: 100vh;
  // width: 100vw;
}
.login-div {
  background: url('../../assets/login/logo_bg.png') no-repeat;
  background-size: cover;
  // height: 100%;
  // width: 100%;
}
.logo{
  padding: 25px 70px;
  .img{
    width: 200px;
    height: 61.25px;
  }
}
.logins {
  padding: 6% 0px;
  padding-top: 0%;
}
.login-box {
  width: 1193px;
  min-width: 1193px;
  margin: 0px auto;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  .img-boxs {
    width: 597px;
    min-width: 597px;
    height: 710px;
  }
  .img {
    width: 597px;
    height: 100%;
  }
}
.forms-div {
  padding: 0px 80px;
  margin: auto;
  h1 {
    text-align: center;
    margin-top: -8px;
    padding-bottom: 44px;
    margin-bottom: 44px;
    padding: 0px 15px 8px;
    border-bottom: 1px solid #eee;
  }
  /deep/ .el-form-item__content .el-input__inner,
  .el-form-item__label .el-input__inner {
    height: 48px;
    line-height: 48px;
  }
  /deep/ .el-form--label-top .el-form-item__label {
    padding: 0px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
  }
  /deep/ .el-input {
    .el-input__inner {
      border: 0px;
      border-bottom: 1px solid #eee;
      padding-left: 0px;
      &::placeholder {
        color: #999999;
      }
    }
  }
  /deep/ .el-select {
    .el-input__inner {
      border: 1px solid #eee;
      padding-left: 15px;
      &::placeholder {
        color: #666666;
      }
    }
  }
  .btn-group {
    /deep/ .el-button {
      width: 100%;
      height: 48px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
    }
    /deep/ .el-button--primary {
      background-color: #1e8cff;
      border-color: #1e8cff;
      &:hover{
        background-color: rgba(30,140,255,.95);
      }
    }
  }
  /deep/ .el-checkbox__label{
    font-size: 16px;
    font-weight: 500;
  }
  /deep/ .el-checkbox__inner::after{
    height: 8px;
    left: 5px;
  }
  /deep/ .el-checkbox__inner{
    width: 16px;
    height: 16px;
  }
}

.foot {
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  p {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #999999;
    text-align: center;
  }
}
</style>
